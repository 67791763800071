import React from "react";
import "../css/steps.css";

function Steps() {
  return (
    <section className="Steps" id="hoe">
      <div className="cstm-container">
        <header className="subtitle">
          <h2>Hoe meld ik mij aan?</h2>
        </header>
        <article className="steps">
          <div className="steps-item">
            <div className="number">1</div>
            <div className="step-content">
              <h3 className="step-title">Vul het formulier in.</h3>
              <p>
                Vul de gegevens in die nodig zijn en word een lesgever bij Stupu
                (Students4Pupils).
              </p>
            </div>
          </div>
          <div className="steps-item">
            <div className="number">
              2
            </div>
            <div className="step-content">
              <h3 className="step-title">Wij valideren jouw gegevens. </h3>
              <p>
                Dit is een standaard procedure om jou tot bijlesgever in te schrijven. 
              </p>
            </div>
          </div>
          <div className="steps-item">
            <div className="number">3</div>
            <div className="step-content">
              <h3 className="step-title">We nemen contact met jou op.</h3>
              <p>
                Nadat al je gegevens goedgekeurd zijn, word je gecontacteerd om als bijlesgever van start te gaan.
              </p>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}

export default Steps;
