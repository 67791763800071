import React, { useState } from "react";
import ConfirmPopUp from "./confirmPopUp";
import Loader from "./loader";
import "../css/form.css";

function Form() {
  const [birthdateInputType, setBirthdateInputType] = useState("text");
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handlePopUpClose = () => {
    setShowPopup(false);
  };

  const validateName = (name) => (!name ? "Naam is verplicht*" : "");
  const validateSurname = (surname) =>
    !surname ? "Achternaam is verplicht*" : "";
  const validateEmail = (email) => {
    if (!email) return "Email is verplicht";
    if (!/\S+@\S+\.\S+/.test(email)) return "Ongeldige email*";
    return "";
  };
  const validatePhone = (phone) => {
    if (!phone) return "Telefoonnummer is verplicht"; // Phone number is required
    const belgiumPhoneRegex = /^(?:(?:\+32|0)4[0-9]{8})$/;
    if (!belgiumPhoneRegex.test(phone)) return "Ongeldig telefoonnummer*"; // Invalid phone number format
    return ""; // No error
  };
  const validateBirthdate = (birthdate) =>
    !birthdate ? "Geboortedatum is verplicht*" : "";
  const validateLocation = (location) =>
    !location ? "Geboorteplaats is verplicht*" : "";
  const validateAddress = (address) => (!address ? "Adres is verplicht*" : "");
  const validateCity = (city) => (!city ? "Gemeente is verplicht*" : "");
  const validatePostalcode = (postalcode) => {
    if (!postalcode) return "Postcode is verplicht";
    if (!/^\d{4,5}$/.test(postalcode))
      return "Postcode moet 4 of 5 cijfers bevatten*";
    return "";
  };
  const validateGraduation = (graduation) =>
    !graduation ? "Opleiding is verplicht*" : "";
  const validateYear = (year) => (!year ? "Jaar is verplicht*" : "");
  const validateSchool = (school) => (!school ? "School is verplicht*" : "");
  const validateStudentnr = (studentnr) => {
    if (!studentnr) return "Studentennummer is verplicht*";
    if (!/^\d+$/.test(studentnr))
      return "Studentennummer moet alleen nummers bevatten*";
    return "";
  };

  const validateCheckbox = (checked) =>
    !checked ? "Je moet akkoord gaan met het privacybeleid*" : "";

  const handleChange = (e) => {
    const { id, value, checked } = e.target;
    switch (id) {
      case "first_name":
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: validateName(value),
        }));
        break;
      case "last_name":
        setErrors((prevErrors) => ({
          ...prevErrors,
          surname: validateSurname(value),
        }));
        break;
      case "email":
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: validateEmail(value),
        }));
        break;
      case "phone_no_":
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: validatePhone(value),
        }));
        break;
      case "birth_date":
        setErrors((prevErrors) => ({
          ...prevErrors,
          birthdate: validateBirthdate(value),
        }));
        break;
      case "municipality":
        setErrors((prevErrors) => ({
          ...prevErrors,
          location: validateLocation(value),
        }));
        break;
      case "street":
        setErrors((prevErrors) => ({
          ...prevErrors,
          address: validateAddress(value),
        }));
        break;
      case "city":
        setErrors((prevErrors) => ({
          ...prevErrors,
          city: validateCity(value),
        }));
        break;
      case "postalcode":
        setErrors((prevErrors) => ({
          ...prevErrors,
          postalcode: validatePostalcode(value),
        }));
        break;
      case "course":
        setErrors((prevErrors) => ({
          ...prevErrors,
          graduation: validateGraduation(value),
        }));
        break;
      case "year":
        setErrors((prevErrors) => ({
          ...prevErrors,
          year: validateYear(value),
        }));
        break;
      case "school":
        setErrors((prevErrors) => ({
          ...prevErrors,
          school: validateSchool(value),
        }));
        break;
      case "student_no_":
        setErrors((prevErrors) => ({
          ...prevErrors,
          studentnr: validateStudentnr(value),
        }));
        break;
      case "checkbox1":
        setErrors((prevErrors) => ({
          ...prevErrors,
          checkbox: validateCheckbox(checked),
        }));
        break;
      default:
        break;
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      name: validateName(document.getElementById("first_name").value),
      surname: validateSurname(document.getElementById("last_name").value),
      email: validateEmail(document.getElementById("email").value),
      phone: validatePhone(document.getElementById("phone_no_").value),
      birthdate: validateBirthdate(document.getElementById("birth_date").value),
      location: validateLocation(document.getElementById("municipality").value),
      address: validateAddress(document.getElementById("street").value),
      city: validateCity(document.getElementById("city").value),
      postalcode: validatePostalcode(
        document.getElementById("postalcode").value
      ),
      graduation: validateGraduation(
        document.getElementById("course").value
      ),
      school: validateSchool(document.getElementById("school").value),
      school_year: validateYear(document.getElementById("year").value),
      studentnr: validateStudentnr(document.getElementById("student_no_").value),
      checkbox: validateCheckbox(document.getElementById("checkbox1").checked),
    };

    setErrors(newErrors);
    const isValid = Object.keys(newErrors).every((key) => !newErrors[key]);

    if (isValid) {
      setSubmitting(true);
    
      const formData = {
        first_name: document.getElementById("first_name").value,
        last_name: document.getElementById("last_name").value,
        "e-mail": document.getElementById("email").value,
        telephone_no_: document.getElementById("phone_no_").value,
        birth_date: document.getElementById("birth_date").value,
        birth_place: document.getElementById("city").value, 
        street: document.getElementById("street").value,
        municipality: document.getElementById("municipality").value,
        postal_code: document.getElementById("postalcode").value,
        student_no_: document.getElementById("student_no_").value,
        university: document.getElementById("school").value,
        school_year: validateYear(document.getElementById("year").value),
        course: document.getElementById("course").value,
      };

      fetch("https://stupufncapp.azurewebsites.net/api/stupu/contact?code=zYRGsZ6o-ZIyLl0u3EPafxOrYJ3x177Ll1Db1SjAjaKXAzFuTTpUMw%3D%3D", { 
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          console.log("Raw response:", response);
          if (!response.ok) {
            throw new Error(`Server error: ${response.status}`);
          }
        })
        .then((data) => {
          console.log("Success:", data); 
          setSubmitting(false);
          setShowPopup(true);
        })
        .catch((error) => {
          console.error("Error:", error);
          setSubmitting(false);
        });
    } else {
      console.log("Kan niet worden verstuurd");
    }
  };
    

  return (
    <section className="Form" id="formulier">
      <div className="cstm-container">
        <header className="subtitle subtitle-dark">
          <h2>Schrijf je in!</h2>
        </header>
        <article className="form">
          <form onSubmit={handleSubmit} noValidate>
            <h3>Persoonsgegevens:</h3>
            <div className="group-input">
              <div className="group-input-input">
                <label htmlFor="first_name"></label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="Voornaam"
                  autoComplete="first_name"
                  onChange={handleChange}
                  required
                />
                {errors.name && (
                  <span className="error-message">{errors.name}</span>
                )}
              </div>
              <div className="group-input-input">
                <label htmlFor="last_name"></label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Achternaam"
                  autoComplete="last_name"
                  onChange={handleChange}
                  required
                />
                {errors.surname && (
                  <span className="error-message">{errors.surname}</span>
                )}
              </div>
            </div>
            <div className="group-input">
              <div className="group-input-input">
                <label htmlFor="email"></label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="email"
                  onChange={handleChange}
                  required
                />
                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>
              <div className="group-input-input input-50">
                <label htmlFor="phone_no_"></label>
                <input
                  type="tel"
                  id="phone_no_"
                  name="phone_no_"
                  placeholder="Telefoonnummer"
                  onChange={handleChange}
                  required
                />

                {errors.phone && (
                  <span className="error-message">{errors.phone}</span>
                )}
              </div>
            </div>
            <div className="group-input">
              <div className="group-input-input input-50">
                <label htmlFor="birth_date"></label>
                <input
                  type={birthdateInputType}
                  id="birth_date"
                  name="birth_date"
                  placeholder="Geboortedatum"
                  onFocus={() => setBirthdateInputType("date")}
                  onBlur={() =>
                    !document.getElementById("birth_date").value &&
                    setBirthdateInputType("text")
                  }
                  max="2024-09-14"
                  onChange={handleChange}
                  required
                />
                {errors.birthdate && (
                  <span className="error-message">{errors.birthdate}</span>
                )}
              </div>
              <div className="group-input-input">
                <label htmlFor="municipality"></label>
                <input
                  type="text"
                  id="municipality"
                  name="municipality"
                  placeholder="Geboorteplaats"
                  onChange={handleChange}
                  required
                />
                {errors.location && (
                  <span className="error-message">{errors.location}</span>
                )}
              </div>
            </div>
            <div className="group-input">
              <div className="group-input-input">
                <label htmlFor="adress"></label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  placeholder="Straat + Nummer"
                  autoComplete="adress"
                  onChange={handleChange}
                  required
                />
                {errors.address && (
                  <span className="error-message">{errors.address}</span>
                )}
              </div>
              <div className="group-input-input input-75">
                <label htmlFor="city"></label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  placeholder="Gemeente"
                  onChange={handleChange}
                  required
                />
                {errors.city && (
                  <span className="error-message">{errors.city}</span>
                )}
              </div>
              <div className="group-input-input input-50">
                <label htmlFor="postalcode"></label>
                <input
                  type="text"
                  id="postalcode"
                  name="postalcode"
                  placeholder="Postcode"
                  pattern="\d{4,5}"
                  onChange={handleChange}
                  required
                />
                {errors.postalcode && (
                  <span className="error-message">{errors.postalcode}</span>
                )}
              </div>
            </div>
            <h3>Studentengegevens:</h3>
            <div className="group-input">
              <div className="group-input-input input-200">
                <label htmlFor="course"></label>
                <input
                  type="text"
                  id="course"
                  name="course"
                  placeholder="Opleiding"
                  autoComplete="course"
                  onChange={handleChange}
                  required
                />
                {errors.graduation && (
                  <span className="error-message">{errors.graduation}</span>
                )}
              </div>
              <div className="group-input-input input-75">
                <label htmlFor="year"></label>
                <input
                  type="number"
                  id="year"
                  name="year"
                  placeholder="Jaar"
                  autoComplete="year"
                  onChange={handleChange}
                  min="2015"
                  defaultValue="2024"
                  required
                />
                {errors.year && (
                  <span className="error-message">{errors.year}</span>
                )}
              </div>
              <div className="group-input-input input-100">
                <label htmlFor="school"></label>
                <input
                  type="text"
                  id="school"
                  name="school"
                  placeholder="School"
                  autoComplete="school"
                  onChange={handleChange}
                  required
                />
                {errors.school && (
                  <span className="error-message">{errors.school}</span>
                )}
              </div>
            </div>
            <div className="group-input">
              <div className="group-input-input input-150">
                <label htmlFor="studentnr"></label>
                <input
                  type="text"
                  id="student_no_"
                  name="student_no_"
                  placeholder="Studentennummer"
                  autoComplete="student_no_"
                  onChange={handleChange}
                  required
                />
                {errors.studentnr && (
                  <span className="error-message">{errors.studentnr}</span>
                )}
              </div>
              <div className="group-input-input">
                <div className="checkbox">
                  <label htmlFor="checkbox1"></label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="checkbox1"
                    name="checkbox"
                    autoComplete="checkbox"
                    onChange={handleChange}
                    required
                  />
                  <div className="checkbox-text">
                    Ik ga akkoord met het privacy beleid.
                  </div>
                </div>
                {errors.checkbox && (
                  <span className="error-message">{errors.checkbox}</span>
                )}
              </div>
            </div>
            <button className="w-100 mt-2" type="submit">
              VERSTUUR!
            </button>
          </form>
        </article>
        {submitting && <Loader />}
        {showPopup && <ConfirmPopUp onClose={handlePopUpClose} />}
      </div>
    </section>
  );
}

export default Form;
